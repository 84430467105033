import React, { useState, useEffect } from 'react'
import {
    Create ,
    SimpleForm,
    TextInput,
    required, 
    useRedirect,
    useNotify,
    Toolbar,
    SaveButton
} from "react-admin";
import axios from "axios";
import { apiUrl } from "../ApiUrl";
import { Grid,InputAdornment,TextField  } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { set, useFormContext,useWatch } from  'react-hook-form'

const RoleCreate = props =>
{
    const [branches, setBranches] = React.useState([]);
    const [branch, setBranch] = React.useState('');
    const [accountNoValue, setAccountNoValue] = React.useState('');
    const [accountNameValue, setAccountNameValue] = React.useState('');
    const [userInfo, setUserInfo] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("auth");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
      const req = [required()];

      useEffect(() => { 
            if(userInfo.accountParentId !== 0)
            {
                axios.get(apiUrl + "/CollectShipper?accountParentId=" + userInfo.accountParentId + "&clientId=" + userInfo.clientId , {
                
                })
                .then((res) => {
                    setBranches(res.data);
                    console.log(branches);
                });

            }
    },[userInfo]);

    const transform = data => ({
        ...data,
        clientId:userInfo.clientId,
        accountNo:accountNoValue,
        userName:userInfo.userName,
        accountName:accountNameValue
    });

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 90, hide:true,},
        { field: 'accessPagePerRoleHeaderId', headerName: 'accessPagePerRoleHeaderId', width: 90,hide:true, },
        { field: 'accessPagePerRoleDetailId', headerName: 'accessPagePerRoleDetailId', width: 90,hide:true, },
        { field: 'pageId', headerName: 'Id', width: 90, hide:true,},
        {
          field: 'page',
          headerName: 'Page',
          width: 400,
          editable: false,
          sortable: false,
        },
        {
          field: 'isCreate',
          headerName: 'Create',
          width: 90,
          editable: true,
          sortable: false,
        },
        {
            field: 'isEdit',
            headerName: 'Edit',
            width: 90,
            editable: true,
            sortable: false,
          },
          {
            field: 'isDelete',
            headerName: 'Delete',
            width: 90,
            editable: true,
            sortable: false,
          },
          {
            field: 'isBulkUpload',
            headerName: 'Bulk Upload',
            width: 120,
            editable: true,
            sortable: false,
          },
          {
            field: 'isDownloadTemplate',
            headerName: 'Download Template',
            width: 150,
            editable: true,
            sortable: false,
          },
      ];
      
      const rows = [
        { id:1,pageId: 1, page: 'Bookings',isCreate: 'x',isEdit:'x',isDelete:'',isBulkUpload:'x',isDownloadTemplate:'x'},
        { id:2,pageId: 2, page: 'Reports',isCreate: 'x',isEdit:'',isDelete:'',isBulkUpload:'',isDownloadTemplate:''},
        { id:3,pageId: 3, page: 'Roles',isCreate: '',isEdit:'',isDelete:'',isBulkUpload:'',isDownloadTemplate:''},
      ];
      const notify = useNotify();
      const MyToolbar = () => {
        const { reset } = useFormContext();
       
        const redirect = useRedirect();
        return (
            <Toolbar>
                <SaveButton 
                    label="Save"
                    mutationOptions={
                        {
                            onSuccess: (data) => {
                                notify('Successfully saved.');
                                redirect('/roles');
                            },
                            onError:({status,message,body,json})=>{
                                notify(`${body}`);
                            }
                        } 
                    }
                    type="button"
                />
            </Toolbar>
        );
    };

    return(
        <div>
            <Create {...props}   transform={transform} toolbar={<MyToolbar />} >
            <SimpleForm {...props}  transform={transform} toolbar={<MyToolbar />}>
                <Grid container spacing={1} toolbar={<MyToolbar />} >
                    <Grid item xs={12} sm={5}>
                        <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    options={branches.map((option) => option.name)}
                                    renderInput={(params) => <TextField {...params} label="Branch" 
                                                                    onChange={(e) => {
                                                                        
                                                                        setBranch(e.target.value);
                                                                    }}
                                                            />}
                                    onChange={(event, newValue) => {
                                            setAccountNoValue('');
                                            setAccountNameValue('');
                                            const result = branches.find( ({ name }) => name === newValue );
                                            setAccountNoValue(result.accountNo);
                                            setAccountNameValue(newValue);
                                        }
                                    }
                                />
                    </Grid> 
                    <Grid item xs={12} sm={6}> </Grid>
                    <Grid item xs={12} sm={5}> 
                        <TextField 
                            label ="Account No"
                            disabled
                            fullWidth 
                            value={accountNoValue} />           
                    </Grid>
                    <Grid item xs={12} sm={12}> 
                         <Divider />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                             <TextInput 
                                 label ="Code"
                                 source="code"
                                 fullWidth 
                                 validate={req} 
                              />  
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextInput 
                                 label ="Description"
                                 source="description"
                                 fullWidth 
                                 sx={{
                                    marginTop:'-25px'
                                 }}
                                 validate={req} 
                              />  
                    </Grid>
                    <Grid item xs={12} sm={12}>
                       <strong> <p>Pages</p></strong>
                    <Box sx={{ height: 250, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            checkboxSelection
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                        </Box>
                    </Grid>            
                 </Grid>
                 </SimpleForm>        
            </Create>
        </div>
    );
};
export default RoleCreate;