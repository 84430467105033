import React, { useEffect, useState} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { Grid } from "@mui/material";
import { apiUrl } from "../ApiUrl";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CardWithIcon from "./CardWithIcon";
import { CancelPresentation,ControlPoint,CropFree } from "@mui/icons-material";
import axios from "axios";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

  const Dashboard = props =>
    {
      const [issued, setIssued] = React.useState(''); 
      const [remaining, setRemaining] = React.useState('');
      const [used, setUsed] = React.useState('');
      const [cancelled, setCancelled] = React.useState('');
      const [pickupDetails, setPickupDetails] = React.useState();
      const [midLastMile, setMidLastMile] = React.useState();


        const [userInfo] = useState(() => {
            // getting stored value
            const saved = localStorage.getItem("auth");
            const initialValue = JSON.parse(saved);
            return initialValue || "";
          });
      
          useEffect(() => { 
            axios.get(apiUrl + "/Dashboard?clientId=" + userInfo.clientId 
                                                      + "&username="  + userInfo.userName
                                                      + "&accountNo="  + userInfo.accountNo
                                                      , {
            })
            .then((res) => {
              
              const splitCount = res.data.remainingCount.split("/");
              const options = {  maximumFractionDigits: 2   }   

              setIssued(Intl.NumberFormat("en-US",options).format(splitCount[1]));
              setUsed(Intl.NumberFormat("en-US",options).format(res.data.usedCount));
              setCancelled(Intl.NumberFormat("en-US",options).format(res.data.cancelledCount));
              setRemaining(Intl.NumberFormat("en-US",options).format(splitCount[0]));

              setPickupDetails(res.data.pickupDetails);
              setMidLastMile(res.data.midLastmiles);
              console.log(res.data.midLastmiles);

            });
      
          },[userInfo]);
        
        return(
            <div>
              <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12}>
                    <Card>
                          <CardHeader title={"Welcome, " +  userInfo.fullName + "!"} />
                          <CardContent>Online booking dashboard</CardContent>
                      </Card>
                      </Grid>
                </Grid>
           
                <Grid container columnSpacing={2} marginTop={3}>
                    <Grid item xs={12} sm={3}>
                        <CardWithIcon
                              to="/commands"
                              icon={QrCodeScannerIcon}
                              title={'Issued Series'}
                              subtitle={issued}
                              
                          />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                            <CardWithIcon
                                  to="/commands"
                                  icon={ControlPoint}
                                  title={'Used Series'}
                                  subtitle={used}
                                  
                              />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                           <CardWithIcon
                                  to="/commands"
                                  icon={CancelPresentation}
                                  title={'Cancelled Series'}
                                  subtitle={cancelled}
                                  
                              />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                           <CardWithIcon
                                  to="/commands"
                                  icon={CropFree}
                                  title={'Remaining Series'}
                                  subtitle={remaining}
                                  
                              />
                    </Grid>
                </Grid>

                <Grid container columnSpacing={2} marginTop={3}>
                     <Grid item xs={12} sm={6}>
                      <Card sx={{ flex: 1 }}>
                          <CardHeader title={'First mile'} />
                          <ResponsiveContainer width="100%" height={350} >
                          <LineChart
                            width={500}
                            height={300}
                            data={pickupDetails}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line type="monotone" dataKey="booking" name="Booking" stroke="#8884d8" activeDot={{ r: 8 }} />
                          <Line type="monotone" dataKey="cancelled" name="Cancelled" stroke="#82ca9d" />
                        </LineChart>
                        </ResponsiveContainer>
                      </Card>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                     <Card sx={{ flex: 1 }}>
                          <CardHeader title={'Mid and Last mile'} />
                          <ResponsiveContainer width="100%" height={350}>
                          <LineChart
                            width={500}
                            height={300}
                            data={midLastMile}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line type="monotone" dataKey="delivered" name="Delivered" stroke="#8884d8" activeDot={{ r: 8 }} />
                          <Line type="monotone" dataKey="returnToCustomer" name="Return To Customer" stroke="#82ca9d" />
                          <Line type="monotone" dataKey="undelivered" name="Undelivered" stroke="#82ca9d" />
                          <Line type="monotone" dataKey="intransit" name="Intransit" stroke="#82ca9d" />
                        </LineChart>
                        </ResponsiveContainer>
                      </Card>
                     </Grid>
                </Grid>
           </div>
        )

    }
    export default Dashboard;
 