import React, { useState} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import Divider from '@mui/material/Divider';
import {  Grid,TextField} from '@mui/material';
import Typography from "@mui/material/Typography";
import { apiUrl } from './ApiUrl';
import Checkbox from "@mui/material/Checkbox";
import {
   useNotify
 } from "react-admin";

import DialogContentText from "@mui/material/DialogContentText";
const Signup = props =>{
   const { open, onClose } = props
   const [ setOpenConfirm] = useState(false);
   const [openTerms, setOpenTerms] = useState(false);
   const handleTerms = (e) => {
      setOpenTerms(true);
    }
    
   const closeDialog = () => setOpenTerms(false);
   const notify = useNotify();
   const [acceptPrivacy, setAcceptPrivacy] = useState(false);
   const [signupDto, setSignupDto] = useState({
         id:"",
         name: "",
         firstName:"",
         lastName:"",
         mobileNo:"",
         email:"",
         department:"",
         accountNo:"",
         password:"",
         paymode:"",
         role:""
    });
  
    const handleChange = (event) => {
      setSignupDto({
        ...signupDto,
        [event.target.name]: event.target.value
      });
    };
    
   function ValidateEmail(email) 
   {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
      {
         return (true)
      }
      return (false)
   }
   function ValidatePassword(password) {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
    }

   const handleClose = () => {
      setOpenConfirm(false);
      onClose(false);
    };
    function handleSignUp (e) {
      if (acceptPrivacy === false) {
         notify("Please select the checkbox.");
         return;
       }
      if(  signupDto.firstName.length === 0 )
      {
         notify('Firt name is required.');
         return;
      }
      if(  signupDto.lastName.length === 0 )
      {
         notify('Last name is required.');
         return;
      }
      if( signupDto.mobileNo.length === 0 )
      {
         notify('Mobile number is required.');
         return;
      }
      if(  signupDto.email.length === 0 )
      {
         notify('Email is required.');
         return;
      }
      if(!ValidateEmail(signupDto.email))
      {
       notify('You have entered an invalid email address!');
       return;
      }
      
      if(  signupDto.password.length === 0 )
      {
         notify('New password is required.');
         return;
      }

      

      if(!ValidatePassword(signupDto.password))
      {
       notify('Password must be minimum eight characters at least one uppercase, one lowercase, one special character and one number.');
       return;
      }

      let promises = [];
      if(signupDto.email.length !== 0 )
      {
         
         const result =  axios.get(apiUrl + "/signup?accountNo=" + signupDto.accountNo + "&userName=" + signupDto.email  ,{
                  })
               .then((res) => {
                  if(res.data==="User")
                  {
                     notify('Email address already exists.');
                     return;
                  }
                  else if(res.data==="InvalidAccount")
                  {
                     notify('Account number does not exists.');
                     return;
                  }
                  else{
                            const responseSignup =  axios.post(apiUrl + "/signup/"  ,{
                                       name: signupDto.firstName + " " + signupDto.lastName,
                                       mobileNo: signupDto.mobileNo,
                                       email:signupDto.email,
                                       department:signupDto.department,
                                       accountNo:signupDto.accountNo,
                                       password:signupDto.password
                                    })
                                 .then((res) => {
                                    // setOpenConfirm(true); 
                                    
                                    notify("User registered. Wait until admin approves your registration.");
                                    handleClose();
                                 });
                                 promises.push(responseSignup); 
                  }
               });
               promises.push(result);   
                 
      }
      
    };

 return(   
   <div>
   <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "100%"  , height: "100%" }  }}
   >
      <DialogTitle id="alert-dialog-title" 
      >
        <strong> {"Sign Up"} </strong>
        
      </DialogTitle>
      <Divider/>
       <DialogContent>
              <Grid container columnSpacing={1} >
                  <Grid item xs={12} sm={6}>
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="First name"
                        name="firstName"
                        defaultValue={signupDto.firstName}
                        onChange={handleChange}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Last name"
                        name="lastName"
                        defaultValue={signupDto.lastName}
                        onChange={handleChange}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Mobile number"
                        name="mobileNo"
                        defaultValue={signupDto.mobileNo}
                        onChange={handleChange}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Email"
                        name="email"
                        defaultValue={signupDto.email}
                        onChange={handleChange}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField
                        margin="normal"
                        fullWidth
                        label="Department"
                        name="department"
                        defaultValue={signupDto.department}   
                        onChange={handleChange}                                            
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <TextField
                        margin="normal"
                        fullWidth
                        label="Account number"
                        name="accountNo"
                        defaultValue={signupDto.accountNo} 
                        onChange={handleChange}                       
                     />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                     <TextField
                        margin="normal"
                        fullWidth
                        required
                        label="New password"
                        name="password"
                        type="password"
                        defaultValue={signupDto.password}
                        onChange={handleChange}
                        helperText="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                     />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                 {/* <Typography fontSize={12} fontWeight={"bold"}>JOIN US NOW !</Typography>
                  <Typography fontSize={12} >2GO Express in now offering Online Booking for your e-commerce shipment.</Typography>  */}
                  <Typography fontSize={12}>  <Checkbox
                checked={acceptPrivacy}
                onChange={(e) => {
                  console.log(e.target.checked);
                  setAcceptPrivacy(e.target.checked);
                }}
              />
              I have read and understand the 
              <Button variant="text" onClick={handleTerms}>terms and conditions </Button>
              and I authorize 2GO
              and my Company to process my personal data.
              </Typography> 
                  </Grid>
                </Grid>  
      </DialogContent>
      <Divider/>
    <DialogActions>
      <Button variant="text" 
         color='secondary'
         onClick={handleClose}>Cancel</Button>
      <Button variant="contained" onClick={handleSignUp}>Sign Up</Button>
   </DialogActions>
   </Dialog>
   <Dialog
    open={openTerms}
    onClose={closeDialog}>
     <DialogTitle id="form-dialog-title">Data Privacy Policy</DialogTitle>
        <DialogContent>
          <Typography fontWeight={"bold"}>Privacy Notice:</Typography>
          <DialogContentText>
            As part of your Company’s requirements, you will be given access to
            the 2GO Express Booking Portal (“Booking Portal”). 2GO shall
            therefore process your personal data (“data”) such as your name,
            contact number, branch assignment, department, and email address for
            the purpose of registering you to the Booking Portal as an
            authorized user. Your data will be accessible to 2GO IT system
            administrators and your Company’s authorized users & system
            administrators. 2GO will not share your data to third parties unless
            required by law. Your data will be stored in secure servers within
            and outside the Philippines until your Company instructs 2GO to
            safely delete your data. To know how 2GO protects your personal
            data, you may visit{" "}
            <a href="https://www.2go.com.ph/privacy-policy/" target="_blank">
              {" "}
              https://www.2go.com.ph/privacy-policy/
            </a>{" "}
            .
          </DialogContentText>
          <p> </p>
          <DialogContentText>
            For information on how your Company will process your data, to
            exercise your rights to privacy, or contact your Data protection
            Officer, you may visit{" "}
            <a
              href=" https://www.sminvestments.com/privacy-policy/"
              target="_blank"
            >
              {" "}
              https://www.sminvestments.com/privacy-policy/
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
   </Dialog>
 
   </div>
 );
}

export default Signup;