import axios from "axios";
import React, { useState, useEffect } from 'react'
import Divider from '@mui/material/Divider';
import { Button, Grid , TextField} from '@mui/material';
import { Label } from "@mui/icons-material";

const ConfirmContent = props =>
{
    
    return(
            <Grid>
                <Grid item xs={7} sm={7}>
                <p>Please enter reason for booking cancellation.</p>
                </Grid>
                <Grid item xs={7} sm={6}>
                <TextField autoFocus
                        required
                        id="outlined-required"
                        label="Reason"
                        style={{ width: 400  }}  
                        onChange={(e) => {
                            props.passData(e.target.value);
                          }}
                        />
                </Grid>
            </Grid>
         );
};

export default ConfirmContent;