import React from "react";
import { AppBar } from "react-admin";
import MyUserMenu from "./MyUserMenu";

const MyAppBar = props =>{
 return(   
    <AppBar {...props}  userMenu={<MyUserMenu />}   >
        
    </AppBar>
 );
}

export default MyAppBar;