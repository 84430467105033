import { apiUrl } from "../ApiUrl";
  
export default {
   
    login: ({ username, password }) =>  {
        const request = new Request(apiUrl + '/authenticate', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                    localStorage.setItem('auth', JSON.stringify(auth));
                    localStorage.setItem('2goUserClientId', 5);
                    Promise.resolve();
            })
            .catch(() => {
                throw new Error('Invalid username / password')
            });
    },
    logout: () => {
        localStorage.removeItem('tempauth');
        localStorage.removeItem('auth');
        localStorage.removeItem('2goUserClientId');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({status } ) => {
        if( status== 500){
        }
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            localStorage.removeItem('2goUserClientId');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        localStorage.removeItem('2goUserClientId');
        const goUserClientId = localStorage.getItem('2goUserClientId');
        return goUserClientId ? Promise.resolve(goUserClientId) : Promise.reject();
    },
    getIdentity: () => {
        try {
            const { 
                    id, 
                    clientId,
                    fullName, 
                    avatar ,
                    userName,
                    emailAddress,
                    position,
                    isActive,
                    accountNo,
                    department,
                    contactNo,
                    userAddress,
                    accountName,
                    accountAddress,
                    originPort,
                    shortName,
                    password,
                    accountParentId,
                    roleId,
                    isAccepted
                  } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve({ 
                id, 
                clientId,
                fullName, 
                avatar ,
                userName,
                emailAddress,
                position,
                isActive,
                accountNo,
                department,
                contactNo,
                userAddress,
                accountName,
                accountAddress,
                originPort,
                shortName,
                password,
                accountParentId,
                roleId,
                isAccepted        
             });
        } catch (error) {
            return Promise.reject(error);
        }
    },

};
