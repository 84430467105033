import React from "react";
import Button from '@mui/material/Button';
import { UserMenu, MenuItemLink,useLogout,Menu    } from "react-admin";
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { forwardRef } from 'react';
import { MenuItem } from '@mui/material';

const MyUserMenu = (props) => {
     
    const MyLogoutButton = forwardRef((props, ref) => {
        const logout = useLogout();
        const handleClick = () => logout();
        return (
            <MenuItem
                onClick={handleClick}
                ref={ref}
            >
                <PowerSettingsNewIcon /> &nbsp;&nbsp;&nbsp;&nbsp;Logout
            </MenuItem>
        );
    });

  return (
    <div>
     <UserMenu   {...props}>
        <MenuItemLink
          to="/my-profile"
          primaryText="My Profile"
          leftIcon={<SettingsIcon />}
        />
      
          <MyLogoutButton />
      </UserMenu>  
    </div>
  );
};

export default MyUserMenu;
