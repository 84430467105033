import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import {
  useGetIdentity,
  useNotify
} from "react-admin";

import axios from "axios";
import { saveAs } from "file-saver";
import { apiUrl } from '../ApiUrl';
 

export default function BulkUploadingModal(props) {


  const { identity, isLoading: identityLoading } = useGetIdentity();
  
  const { open, onClose } = props
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [alreadyClicked, setAlreadyClicked] = React.useState(false);
  const [titleDialog, settitleDialog] = React.useState('Bulk Uploading');
  const handleClose = () => {
    onClose(false);
  };

  function generateUUID() {
    var d = new Date().getTime();
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;
        if(d > 0){
            var r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {
            var r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c=='x' ? r : (r&0x7|0x8)).toString(16);
    });
    return uuid;
};
const notify = useNotify();
  const handleSubmit = (event) => {
   
    event.preventDefault()
    const formData = new FormData();
    formData.append("file", selectedFile);
    if(selectedFile !=null)
    {
      settitleDialog('Uploading and pre-processing, please wait');
      setAlreadyClicked(true);
      axios({
        method: 'post',
        url: apiUrl + '/bulkuploading?clientId=' +  identity.clientId + '&username=' + identity.userName ,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' },
        responseType:"arraybuffer",
        })
        .then(function (response) {
          
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, generateUUID() + ".xlsx");
            setAlreadyClicked(false);
            settitleDialog('Bulk Uploading');
            setSelectedFile(null);
            handleClose();
        })
        .catch(function (response) {
            //handle error
            alert(response);
            console.log(response);
        });
    }
    else{
      notify('Please select file.');
      settitleDialog('Bulk Uploading');
     setAlreadyClicked(false);
    }
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
  }
  const handleBackdropClick = (event) => {
    //these fail to keep the modal open
    event.stopPropagation();
    return false;
  };
  

  return (
    <div>
        <Dialog
            open={open}
            onClose={onClose}
            disableEscapeKeyDown
            disableBackdropClick
            onBackdropClick={handleBackdropClick}
          >
             <DialogTitle id="alert-dialog-title">
                { titleDialog }
              </DialogTitle>
              <DialogContent>
                  {/* <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file" multiple type="file" />
                    <Button variant="contained" component="span">
                      Upload
                    </Button>
                  </label> */}
                  <form onSubmit={handleSubmit}>
                    <input type="file" accept=".xlsx" 
                        onChange={handleFileSelect} 
                        disabled={alreadyClicked}
                        variant="contained" 
                        color="primary"
                       />
                    <Button type="submit" 
                          value="Upload File" 
                          disabled={alreadyClicked}  
                          variant="contained" 
                          color="primary" >Upload File</Button>
                  </form>
             </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}  disabled={alreadyClicked}>Close</Button>
         </DialogActions>
        </Dialog>
    </div>
  );
}