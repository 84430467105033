import React ,{ useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material';

import {
  useNotify ,
  useLogout,
  Confirm
} from "react-admin";
import axios from "axios";
import { apiUrl } from './ApiUrl';

export default function ProfileEdit(props) {
  
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("auth");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const logout = useLogout();

  const [values, setValues] = useState({
    id:userInfo.id,
    fullName: userInfo.fullName,
    department: userInfo.department,
    emailAddress: userInfo.emailAddress,
    contactNo: userInfo.contactNo,
    userName: userInfo.userName,
    password: userInfo.password,
    confirmPassword: userInfo.password
  });

  const notify = useNotify();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const CustomUpdateButton = () => {
    
    const handleConfirm = () => {
        handleUpdate();
    };
  }
function ValidateEmail(email) 
{
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
  {
    return (true)
  }
  return (false)
}
function ValidatePassword(password) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
}

  const handleUpdate =(e)=>{
     if(values.fullName ==null || values.fullName == undefined ||  values.fullName.length == 0 )
     {
        notify('Name is required.');
        return;
     }
     if(values.department ==null || values.department == undefined ||  values.department.length == 0 )
     {
        notify('Deparment is required.');
        return;
     }
     if(values.emailAddress ==null || values.emailAddress == undefined ||  values.emailAddress.length == 0 )
     {
        notify('Email address is required.');
        return;
     }
     if(values.userName ==null || values.userName == undefined ||  values.userName.length == 0 )
     {
        notify('User name is required.');
        return;
     }
     if(values.password ==null || values.password == undefined ||  values.password.length == 0 )
     {
        notify('Password is required.');
        return;
     }
     if(values.confirmPassword ==null || values.confirmPassword == undefined ||  values.confirmPassword.length == 0 )
     {
        notify('Confirm Password is required.');
        return;
     }

     if( values.userName.length < 8 )
     {
        notify('User name minimum of eight characters.');
        return;
     }

     if(!ValidateEmail(values.emailAddress))
     {
      notify('You have entered an invalid email address!');
      return;
     }
     
     if(!ValidatePassword(values.password))
     {

      notify('Password must be minimum eight characters at least one uppercase, one lowercase, one special character and one number.');
      return;
     }
     
     if(values.password != values.confirmPassword)
     {
        notify('The password and confirmation password do not match.');
        return;
     }

        axios.post(apiUrl + "/profile/"  ,{
          id:values.id,
          fullName: values.fullName,
          department: values.department,
          emailAddress: values.emailAddress,
          contactNo: values.contactNo,
          userName: values.userName,
          password: values.password,
          confirmPassword: values.confirmPassword
        })
      .then((res) => {
        if(res.data == "Success")
        {
           logout(); 
        }
        else
        {
          notify(`${res.data}`);
        }
      });
  }
    
    return (
      <form
        autoComplete="off"
        noValidate
        {...props}
      >
        <Card>
          <CardHeader
            subheader="The information can be edited"
            title="Profile"
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Name"
                  name="fullName"
                  onChange={handleChange}
                  required
                  defaultValue={values.fullName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
               <TextField
                  fullWidth
                  label="Department"
                  name="department"
                  required
                  onChange={handleChange}
                  defaultValue={values.department}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Email Address"
                  name="emailAddress"
                  onChange={handleChange}
                  required
                  defaultValue={values.emailAddress}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="contactNo"
                  onChange={handleChange}
                  defaultValue={values.contactNo}
                  variant="outlined"
                />
              </Grid>
             </Grid>
        </CardContent>
        <Card>
          <CardHeader
            title="Login Credential"
          />
          <Divider />
          <CardContent>
          <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="User Name"
                  name="userName"
                  helperText="Minimum eight characters"
                  onChange={handleChange}
                  required
                  defaultValue={values.userName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  helperText="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                  onChange={handleChange}
                  required
                  defaultValue={values.password}
                  type="password"
                  variant="outlined"
                />
              </Grid>
                
              <Grid
                item
                md={6}
                xs={12}
              >
                
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  required
                  defaultValue={values.confirmPassword}
                  variant="outlined"
                />
              </Grid>
              </Grid>

          </CardContent>
          </Card>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {  handleClick(); }}
            >
              Update
            </Button>
            <Confirm
                isOpen={open}
                title="Update Profile"
                content="Are you sure you want to update?User is automatically logged out."
                onConfirm={handleUpdate}
                onClose={handleDialogClose}
            />
          </Box>
        </Card>
      </form>
    );
};
