import React, { useEffect, useState, Fragment } from "react";
import { useLogin, useNotify, Notification, Login } from "react-admin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import image from "./Images/bg.jpg";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import axios from "axios";
import { apiUrl } from "./ApiUrl";
import { alertTitleClasses,Divider } from "@mui/material";
import Signup from "./signup";
import ForgotPassword from "./ForgotPassword";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://2goexp-onlinebooking.2go.com.ph/">
        2GO Express Online Booking
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function TermsConditions(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Terms & Conditions
    </Typography>
  );
}

const theme = createTheme();

const CustomLoginPage = (props) => {
  const [open, setOpen] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);

  const [userId, setUserId] = useState(0);
  const [isAcceptedValue, setisAcceptedValue] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  const handleClickOpen = () => {
    setAcceptPrivacy(false);
    setOpen(true);
  };

  const handleForgotPasword = () => {
      setOpenForgotPassword(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSignUp = (e) => {
    setOpenSignup(true);
  }
  const closeDialog = () => setOpenSignup(false)
  const closeDialogForgotPassword = () => setOpenForgotPassword(false)

  const handleSignin = (e) => {
   
    axios({
      method: "post",
      url: apiUrl + "/authenticate",
      headers: { "Content-Type": "application/json" },
      data: {
        username: username,
        password: password,
      },
      responseType: "json",
    })
      .then((response) => {
        if (response.data.isAccepted === false) {
          setAcceptPrivacy(false);
          setOpen(true);
        }
        setisAcceptedValue(response.data.isAccepted);
        setUserId(response.data.id);
        if(response.data.isAccepted === true){
            login({ username, password }).catch(() =>
            notify("Invalid username / password")
            );
        }
        
      })
      .catch(function (response) {
        notify("Invalid username / password");
        console.log(response);
      });

    //   if (isAcceptedValue !== false) {
    //     login({ username, password }).catch(() =>
    //       notify("Invalid username / password")
    //     );
    //   }
  };

  const handleAccept = () => {
    if (acceptPrivacy === false) {
      notify("Please select the checkbox.");
    } else {
      axios({
        method: "put",
        url: apiUrl + "/authenticate",
        headers: { "Content-Type": "application/json" },
        data: {
          Id: userId,
        },
        responseType: "json",
      })
        .then((response) => {
          setisAcceptedValue(true);
        })
        .catch(function (response) {
          notify("Invalid username / password");
          console.log(response);
        });

      login({ username, password }).catch(() =>
        notify("Invalid username / password")
      );
      handleClose();
    }
  };

  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  return (
    <div >
         <Signup open={openSignup}   onClose={closeDialog} />
      <ForgotPassword open={openForgotPassword}   onClose={closeDialogForgotPassword} />
   
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Data Privacy Policy</DialogTitle>
        <DialogContent>
          <Typography fontWeight={"bold"}>Privacy Notice:</Typography>
          <DialogContentText>
            As part of your Company’s requirements, you will be given access to
            the 2GO Express Booking Portal (“Booking Portal”). 2GO shall
            therefore process your personal data (“data”) such as your name,
            contact number, branch assignment, department, and email address for
            the purpose of registering you to the Booking Portal as an
            authorized user. Your data will be accessible to 2GO IT system
            administrators and your Company’s authorized users & system
            administrators. 2GO will not share your data to third parties unless
            required by law. Your data will be stored in secure servers within
            and outside the Philippines until your Company instructs 2GO to
            safely delete your data. To know how 2GO protects your personal
            data, you may visit{" "}
            <a href="https://www.2go.com.ph/privacy-policy/" target="_blank">
              {" "}
              https://www.2go.com.ph/privacy-policy/
            </a>{" "}
            .
          </DialogContentText>
          <p> </p>
          <DialogContentText>
            For information on how your Company will process your data, to
            exercise your rights to privacy, or contact your Data protection
            Officer, you may visit{" "}
            <a
              href=" https://www.sminvestments.com/privacy-policy/"
              target="_blank"
            >
              {" "}
              https://www.sminvestments.com/privacy-policy/
            </a>
            .
          </DialogContentText>
          <p> </p>
          <Typography fontWeight={"bold"}>
            Privacy Consent:
            <DialogContentText>
              <Checkbox
                checked={acceptPrivacy}
                onChange={(e) => {
                  console.log(e.target.checked);
                  setAcceptPrivacy(e.target.checked);
                }}
              />
              I have read and understand the privacy notice and I authorize 2GO
              and my Company to process my personal data.
            </DialogContentText>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleAccept();
            }}
            color="primary"
          >
            Accept and Continue
          </Button>
        </DialogActions>
      </Dialog>

      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 2,
              border: 1,
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
               <Button
                type="button"
                onClick={() => {
                  handleForgotPasword();
                }}
              >
               Forgot Password?
              </Button>
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  handleSignin();
                }}
              >
                Sign In
              </Button>
              <Divider/>
               {/* <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                       
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#/signup" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>   */}
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => {
                      handleSignUp();
                    }}
                  >
                  Don't have an account? Sign Up
              </Button>
            </Box>
          </Box>

          {/* <TermsConditions sx={{ mt: 4, mb: 4 }} /> */}
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
      
    </div>
  );
};

export default CustomLoginPage;
