import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import Divider from '@mui/material/Divider';
import { Alert, Box, Grid,TextField} from '@mui/material';
import Typography from "@mui/material/Typography";
import { apiUrl } from './ApiUrl';
import Checkbox from "@mui/material/Checkbox";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import {
   Confirm,
   useNotify
 } from "react-admin";

import DialogContentText from "@mui/material/DialogContentText";
const ForgotPassword = props =>{
   const { open, onClose } = props
   const notify = useNotify();
   const [stepTitle, setStepTitle] = useState('I Forgot My Password');
   const [steps, setSteps] = useState('1st');
   const [buttonNextCaption, setButtonNextCaption] = useState('Next');
   const [userId, setUserId] = useState('');
   const [code, setCode] = useState({
      one:"",
      two: "",
      three:"",
      four:"",
      five:"",
      six:"" 
   });

   const [values, setValues] = useState({
      userName: "",
      password: "",
      confirmPassword: ""
    });
  
 function textChange(event)
 {
   setCode({
      ...code,
      [event.target.name]: event.target.value
    });
 
 }
 function PasswordChange(event)
 {
  
   setValues({
      ...values,
      [event.target.name]: event.target.value
    });
 
 }
 function ValidatePassword(password) {
   return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
 }
   const handleClose = () => {
      onClose(false);
    };

    function handleNext() {
            if(steps==='1st')
            {
               if(userId=='')
               {
                  notify("Please enter user ID or Email Address.");
                  return;
               }
               const result =  axios.get(apiUrl + "/ForgotPassword?userId=" + userId ,{
               })
               .then((res) => {
                     if(res.data == true){
                           setStepTitle('We Sent You An E-Mail!');
                           setSteps('2nd')
                      }
                     else
                     {
                        notify("User ID or Email Address does not exists.");
                     }
               });
            
            }
            if(steps==='2nd')
            {
               if(code.one=='' || code.two=='' || code.three=='' || code.four=='' || code.five=='' || code.six=='')
               {
                  notify("Please enter code.");
                  return;
               }
               const result =  axios.get(apiUrl + "/ValidateCode?userid=" + userId + "&one="  + code.one + 
                  "&two=" + code.two + "&three=" + code.three  + "&four=" + code.four
                  + "&five=" + code.five + "&six=" + code.six
               ,{
               })
               .then((res) => {
                  if(res.data == true){
                     setStepTitle('Enter Your New Password');
                     setButtonNextCaption('Change');
                      setSteps('3rd')
                  }
                  else
                  {
                     notify("The code you entered is not valid.");
                  }
                 
               });
              
               
            }
            if(steps==='3rd')
            {
               if(values.password ==null || values.password == undefined ||  values.password.length == 0 )
               {
                  notify('Password is required.');
                  return;
               }
               if(values.confirmPassword ==null || values.confirmPassword == undefined ||  values.confirmPassword.length == 0 )
               {
                  notify('Confirm Password is required.');
                  return;
               }
               
               
               if(!ValidatePassword(values.password))
               {

                  notify('Password must be minimum eight characters at least one uppercase, one lowercase, one special character and one number.');
                  return;
               }
     
               if(values.password != values.confirmPassword)
               {
                  notify('The password and confirmation password do not match.');
                  return;
               }

               axios.post(apiUrl + "/forgotpassword/"  ,{
                  userName: userId,
                  password: values.password,
                  confirmPassword: values.confirmPassword
               })
               .then((res) => {
                  notify('Password has been changed successfully!');
                  handleClose();
                  });
               //
            }
    };

    useEffect(() => { 
         setButtonNextCaption('Next');
         setStepTitle('I Forgot My Password');
         setSteps('1st')
         setUserId('');
         setCode  ({
            one:"",
            two: "",
            three:"",
            four:"",
            five:"",
            six:"" 
         });
          setValues ({
            userName: "",
            password: "",
            confirmPassword: ""
          });
  },[open]);

 return(   
   <div>
   <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "100%"  , height: "100%" }  }}
   >
      <DialogTitle id="alert-dialog-title" 
      >
        <strong> {stepTitle} </strong>
        
      </DialogTitle>
      <Divider/>
       <DialogContent>
         <Box hidden={ steps == '1st'? false:true } alignItems="center"  >
              <Grid container columnSpacing={1} paddingLeft={3}  >
                  <Grid item xs={12} sm={12} >
                        <img
                              src="userid.png"
                              fullWidth
                              style={{ width: 100, height:100 ,marginLeft:200  }}
                           />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                     <Typography startDecorator={<InfoTwoToneIcon />} >
                        Enter your user ID or email address below to recover your password.
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} p={3}>
                     <TextField
                        required
                        fullWidth
                        defaultValue=""
                        id="outlined-required"
                        label="User ID or Email Address"
                        onChange={(e) => {
                           setUserId(e.target.value);
                         }}
                     />
                  </Grid>
              </Grid>
         </Box>
         <Box hidden={ steps == '2nd'? false:true } alignItems="center"  >
              <Grid container columnSpacing={1} paddingLeft={3}  >
                  <Grid item xs={12} sm={12} >
                        <img
                              src="email.png"
                              fullWidth
                              style={{ width: 100, height:100 ,marginLeft:200  }}
                           />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                     <Typography startDecorator={<InfoTwoToneIcon />} >
                     We sent you a code to your registered e-mail address. Once received, input the code below.
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                     <Typography startDecorator={<InfoTwoToneIcon />} paddingTop={2} >
                     The code is valid for 15 minutes only.
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} paddingTop={3} >
                     <TextField
                           sx={{
                              width: 50
                        }}
                        required
                        id="one"
                        label=""
                        name="one"
                        defaultValue={code.one}
                        onChange={textChange}
                        inputProps={{ maxLength: 1 }}
                     />
                      <TextField
                           sx={{
                              width: 65 ,
                              paddingLeft:2
                        }}
                        required
                        id="two"
                        label=""
                        name="two"
                        defaultValue={code.two}
                        onChange={textChange}
                        inputProps={{ maxLength: 1 }}
                     />
                      <TextField
                           sx={{
                              width: 65 ,
                              paddingLeft:2
                        }}
                        
                        required
                        id="three"
                        name="three"
                        defaultValue={code.three}
                        onChange={textChange}
                        label=""
                        inputProps={{ maxLength: 1 }}
                     />
                       <TextField
                           sx={{
                              width: 65 ,
                              paddingLeft:2
                        }}
                        required
                        id="four"
                        name="four"
                        defaultValue={code.four}
                        onChange={textChange}
                        label=""
                        inputProps={{ maxLength: 1 }}
                     />
                      <TextField
                           sx={{
                              width: 65 ,
                              paddingLeft:2
                        }}
                        required
                        id="five"
                        name="five"
                        defaultValue={code.five}
                        onChange={textChange}
                        label=""
                        inputProps={{ maxLength: 1 }}
                     />
                      <TextField
                           sx={{
                              width: 65 ,
                              paddingLeft:2
                        }}
                        required
                        id="six"
                        name="six"
                        defaultValue={code.six}
                        onChange={textChange}
                        label=""
                        inputProps={{ maxLength: 1 }}
                     />
                  </Grid>
              </Grid>
         </Box>
         <Box hidden={ steps == '3rd'? false:true } alignItems="center"  >
              <Grid container columnSpacing={1} paddingLeft={3}  >
                  <Grid item xs={12} sm={12} >
                        <img
                              src="reset.jpg"
                              fullWidth
                              style={{ width: 100, height:100 ,marginLeft:200  }}
                           />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                     <Typography startDecorator={<InfoTwoToneIcon />} >
                     Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} paddingTop={3}>
                     <TextField
                        required
                        fullWidth
                        label="Password"
                        id="password"
                        name="password"
                        onChange={PasswordChange}
                        defaultValue={values.password}
                     />
                  </Grid>
                  <Grid item xs={12} sm={12} paddingTop={3}>
                     <TextField
                        required
                        fullWidth 
                        label="Confirm Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={PasswordChange}
                        defaultValue={values.confirmPassword}
                     />
                  </Grid>
              </Grid>
         </Box>
      </DialogContent>
      <Divider/>
    <DialogActions>
      <Button variant="text" 
         color='secondary'
         onClick={handleClose}>Cancel</Button>
      <Button variant="contained"  onClick={handleNext} >{buttonNextCaption}</Button>
   </DialogActions>
   </Dialog>
 
   </div>
 );
}

export default ForgotPassword;