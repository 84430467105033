import React, { useState, useEffect } from 'react'
import {
    
    SimpleForm,
    TextInput,
    required,
    Edit,
    SaveButton,
    Toolbar,
    useRedirect,
    useNotify
} from "react-admin";
import axios from "axios";
import { apiUrl } from "../ApiUrl";
import { Grid,InputAdornment,TextField  } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { set, useFormContext,useWatch } from  'react-hook-form'

const ApprovalEdit = props =>
{
    const [branches, setBranches] = React.useState([]);
    const [branch, setBranch] = React.useState('');
    const [paymodeValue, setPaymodeValue] = React.useState('');
    const [roleValue, setRoleValue] = React.useState('');
    const [approvalStatusValue, setApprovalStatusValue] = React.useState('');
    const [affiliatesValue, setAffiliatesValue] = useState(false);

    const [accountNoValue, setAccountNoValue] = React.useState('');
    const notify = useNotify();

    const MyToolbar = () => {
      const { reset } = useFormContext();
      const redirect = useRedirect();
      return (
          <Toolbar>
              <SaveButton 
                  label="Savet"
                  mutationOptions={
                      {
                          onSuccess: (data) => {
                              notify('Successfully saved.');
                              redirect('/approvals');
                          },
                          onError:({status,message,body,json})=>{
                              notify(`${body}`);
                          }
                      } 
                  }
                  type="button"
              />
          </Toolbar>
      );
  };
  const redirect = useRedirect();
  const CustomToolbar = props => (
    <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
       <SaveButton 
                    label="Save"
                    alwaysEnable
                    type="button"
                />
    </Toolbar>
);
    const paymodes = [
        { label: 'Collect Shipper', code: 'CS' },
        { label: 'Collect Consignee', code: 'CC' } 
      ];
      const roles = [
        { label: 'Manual Booking', code: 'MNLBKG' },
        { label: 'Bulk Uploading', code: 'BLKUL' } ,
        { label: 'Branch Admin', code: 'BA' } ,
        { label: 'SM Main Admin', code: 'ADMIN' } 
      ];

      const statuses = [
        { label: 'Approved', code: 'APPD' },
        { label: 'Reject', code: 'REJ' }  
      ];
    
    const [userInfo, setUserInfo] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("auth");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
      const req = [required()];

      
    
    
    const transform = data => ({
        ...data,
        clientId:userInfo.clientId ,
        paymode:paymodeValue,
        role:roleValue,
        approvalStatus:approvalStatusValue,
        showAffiliateOnly:affiliatesValue
    });
 
    return(
        <div>
            <Edit {...props}   transform={transform}   >
            <SimpleForm {...props} toolbar={<CustomToolbar />}  transform={transform}    >
                <Grid container spacing={1} {...props}   transform={transform}   >
                    <Grid item xs={12} sm={6}> 
                             <TextInput 
                                 label ="Name"
                                 disabled
                                 source="name"
                                 fullWidth 
                                 validate={req} 
                              />           
                    </Grid>
                    <Grid item xs={12} sm={2}> 
                             <TextInput 
                                 label ="Mobile No"
                                 disabled
                                 source="mobileNo"
                                 fullWidth 
                                 validate={req} 
                              />           
                    </Grid>
                    <Grid item xs={12} sm={4}> 
                             <TextInput 
                                 label ="Email"
                                 disabled
                                 source="email"
                                 fullWidth 
                                 validate={req} 
                              />           
                    </Grid>
                    <Grid item xs={12} sm={3}> 
                             <TextInput 
                                 label ="AccountNo"
                                 disabled
                                 source="accountNo"
                                 fullWidth 
                                 validate={req} 
                              />           
                    </Grid>
                    <Grid item xs={12} sm={5}> 
                             <TextInput 
                                 label ="Account Name"
                                 disabled
                                 source="accountName"
                                 fullWidth 
                                 validate={req} 
                              />           
                    </Grid>

                    <Grid item xs={12} sm={4}> 
                    <Checkbox   
                             onChange={(e) => {
                                setAffiliatesValue(e.target.checked);
                            }}
                           />    
                     <label fullWidth>Show Affiliates Only</label> 
                    </Grid>
                    
                    <Grid item xs={12} sm={5}> 
                             <TextInput 
                                 label ="Department"
                                 source="department"
                                 fullWidth 
                                 validate={req} 
                              />           
                    </Grid>
                    <Grid item xs={12} sm={5}> 
                    <Autocomplete
                                source="paymode"
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={paymodes}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Paymode"
                               
                                />}
                                onChange={(event, newValue) => {
                                    setPaymodeValue(newValue.code);
                                  }}
                    />
                    </Grid>
                    <Grid item xs={12} sm={5}> 
                    <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={roles}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Role"
                               />}
                               onChange={(event, newValue) => {
                                setRoleValue(newValue.code);
                              }} 
                    />
                    </Grid>
                    <Grid item xs={12} sm={5}> 
                    <Autocomplete
                                source="role"
                                disablePortal
                                id="combo-box-demo"
                                fullWidth
                                options={statuses}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Status"
                                />}
                                onChange={(event, newValue) => {
                                    setApprovalStatusValue(newValue.code);
                                  }}
                                 
                    />
                    </Grid>
                 </Grid>
                 </SimpleForm>        
            </Edit>
        </div>
    );
};
export default ApprovalEdit;