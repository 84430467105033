import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
 
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";

import Divider from '@mui/material/Divider';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { apiUrl } from '../ApiUrl';

export default function SearchSAM(props) {
  const { open, onClose } = props
  const { searchValue, setSearchValue } = props

const address = React.useState({
  id:'',
  region: '',
  province: '',
  municipality:'',
  barangay:'',
  portcode:'',
  areacode:'',
  zipcode:'',
  portcode:''
});

  const handleClose = () => {
    onClose(false);
  };
  
  const columns = [
    { field: "id", 
      hide:true,
      headerName: "ID",  },
    {
      field: "region",
      headerName: "Region",
      hide:true,
      sortable: false,
    },
    {
      field: "province",
      headerName: "Province",
      width: 140 ,
      sortable: false,
    },
    {
      field: "municipality",
      headerName: "Municipality",
      width: 140 ,
      sortable: false,
    } ,
    {
      field: "barangay",
      headerName: "Barangay",
      width: 150 ,
      sortable: false,
    } ,
    {
      field: "areaCode",
      headerName: "Area Code",
      width: 70 ,
      sortable: false,
    } ,
    {
      field: "deliveryCategory",
      headerName: "Delivery Category",
      width: 30 ,
      sortable: false,
    } 
  ];

  
  const [tableData, setTableData] = useState([])
  useEffect(() => { 
    
      axios.get(apiUrl + "/ServiceAreaManual?searchValue=" + props.searchValue , {
      })
      .then((res) => {
        setTableData(res.data);
      });

    },[props.searchValue]);
    
     

  return (
    
    <div>
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { width: "100%"  , height: "100%" }  }}
          >
             <DialogTitle id="alert-dialog-title" 
             >
                {"Search (Double click the specific address below to continue.)"}
              </DialogTitle>
              <Divider/>
                <DialogContent>
                    <DataGrid
                      rows={tableData}
                      columns={columns}
                      pageSize={5}
                      
                      components={{ Toolbar: GridToolbar }} 
                      onCellDoubleClick={(params, event) => {
                        if (!event.ctrlKey) {
                          address.id = params.row.id;
                          address.region = params.row.region;
                          address.province = params.row.province;
                          address.municipality = params.row.municipality;
                          address.barangay = params.row.barangay;
                          address.areacode = params.row.areaCode;
                          address.zipcode = params.row.zipCode;
                          address.portcode = params.row.portCode;
                          props.passData(address);
                          handleClose();
                        }
                      }}
                    />
              </DialogContent>
             <Divider/>
          <DialogActions>
              <Button variant="contained" onClick={handleClose}>Close</Button>
         </DialogActions>
        </Dialog>
    </div>
  );
}
