import * as React from "react";
import BookingIcon from "@mui/icons-material/Book";
import {
            Admin,
            Resource,
            fetchUtils,
            CustomRoutes 
        } from "react-admin";
import jsonServerProvider from "ra-data-simple-rest";
import authProvider from "./components/authProvider";
import Dashboard from "./components/Dashboard";

import  BookingList  from './bookings/bookings';
import BookingCreate  from "./bookings/create";
import CustomLoginPage from "./Login";
import Reports from './components/Reports'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { stringify } from 'query-string';
import { apiUrl } from "./ApiUrl";
import MyLayout from "./MyLayout"

import ProfileEdit from './profile'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import RoleList from    './Roles/role'
import RoleCreate from './Roles/create'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import RoleEdit from "./Roles/edit";
import Signup from "./signup";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ApprovalList from "./Approvals/approvals";
import ApprovalEdit from "./Approvals/edit";
import ApprovalCreate from "./Approvals/create";
import UserList from "./Users/Users";

const httpClient = fetchUtils.fetchJson;
 const baseDataProvider  = jsonServerProvider(apiUrl);
 const slowDataProvider = {
    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),
     update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),
    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
      getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
            pagination:JSON.stringify([page,perPage]),
            
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },
    create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
    }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
    })),
    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
  };


const App =() => (
        <Admin  
                dataProvider={slowDataProvider}
                authProvider={authProvider}
                dashboard={Dashboard}
                loginPage={CustomLoginPage}
                layout={MyLayout}
        >
            <CustomRoutes noLayout>
                     <Route path="/signup" element={<Signup />} />
            </CustomRoutes>
            <Resource name="bookings"
                        icon={BookingIcon}
                        list={BookingList}
                        create={BookingCreate}
            /> 
             <Resource name="reports"
                         list={Reports}
                        icon={DynamicFeedIcon} 
                        
                       
            /> 
             <Resource name="roles"
                        icon={SupervisorAccountIcon}
                        list={RoleList}
                        create={RoleCreate}
                        edit={RoleEdit}
            /> 
             <Resource name="approvals"
                        icon={HowToRegIcon}
                        list={ApprovalList}
                        edit={ApprovalEdit}
                        
            /> 
             <Resource name="User" 
                        list={UserList}
                        options={{ label: 'Users' }} 
            />  
            <CustomRoutes>
                    <Route path="/my-profile" element={<ProfileEdit />} />
            </CustomRoutes>
          
        </Admin>
    );
export default App;
