import React, { useEffect, useState,Fragment } from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    TopToolbar,
    CreateButton,
    Pagination,
    Button ,
    useListContext, 
    useUnselect,
    Confirm ,
    useNotify,
    usePermissions,
    useRefresh ,
    useGetIdentity, 
    DateInput,
    useRecordContext,
    useRedirect
} from "react-admin";
import BulkUploadingModal from "../components/BulkUploadingModal";
import axios from "axios";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import jsonServerProvider from "ra-data-simple-rest";
import { saveAs } from "file-saver";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import  ConfirmContent  from '../bookings/confirmContent'
import PrintIcon from '@mui/icons-material/Print';
import { apiUrl } from "../ApiUrl";
import { Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const UserList = props =>
{
    const redirect = useRedirect();
    useEffect(() => { 
        if(userInfo.roleId === 0)
        {
           redirect('/');
        }
        if(userInfo.roleId === 1)
        {
           redirect('/');
        }
        if(userInfo.roleId === 5 || userInfo.roleId === 6 )
        {
           redirect('/');
        }
   },[]);
   
    const [userInfo, setUserInfo] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("auth");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const ListActions = () => (
                 <TopToolbar>
                    <Button 
                    onClick={() => {  handleDownloadUsers(); }}
                        label="Download"
                    >
                        <DownloadIcon/>
                    </Button>
                </TopToolbar>
    
    );

    function uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
      }
    const bookingsFilters = [
        <TextInput label="Search" source="q" 
            alwaysOn placeholder="Enter Account No or Account Name" style={{ width: 400  }}    />,
            <DateInput source="dateFrom" label="Created From" alwaysOn />,
            <DateInput source="dateTo" label="To" alwaysOn   />,
        ];

        const BookingPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

        const handleDownloadUsers =() =>{
            axios({
                method: 'get',
                url:  apiUrl + "/DownloadUsers/" + userInfo.clientId,
                 responseType:"arraybuffer",                         
                
                })
                .then(function (response) {
                    const blob = new Blob([response.data], {
                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    saveAs(blob, uuidv4() + ".xlsx");
                })
                .catch(function (response) {
                    //handle error
                   //np alert(response);
                   
                    console.log(response);
                });
        }
    return(
        <div>
 
          <List {...props}   
                 actions={<ListActions/>}
                //  bulkActionButtons={<BookingBulkActionButtons/>}
                 filters={bookingsFilters} 
                 filter={{ clientId: userInfo.clientId, accountNo:userInfo.accountNo}}
                 pagination={<BookingPagination />}
                 sort={{ field: 'id', order: 'DESC' }}
                 perPage={10}
                 >
                 <Datagrid
               size="small"
               bulkActionButtons={false}
                 >
                     <TextField source="userName" label="User Name"/> 
                     <TextField source="name" label="Name"/> 
                     <TextField source="isActive" label="Active"/> 
                     <TextField source="isApproved" label="Approved"/> 
                     <TextField source="department" label="Department"/> 
                     <TextField source="accountNo" label="Account No"/> 
                     <TextField source="accountName" label="Account Name"/> 
                     <TextField source="dateCreated" label="Date Created"/> 
                 </Datagrid>
            </List>
             
            </div>  
          );
};
export default UserList;