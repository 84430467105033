import React, { useEffect, useState,Fragment } from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    TopToolbar,
    CreateButton,
    Pagination,
    Button ,
    useListContext, 
    useUnselect,
    Confirm ,
    useNotify,
    usePermissions,
    useRefresh ,
    useGetIdentity, 
    DateInput,
    useRecordContext,
    useRedirect
} from "react-admin";
import BulkUploadingModal from "../components/BulkUploadingModal";
import axios from "axios";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import jsonServerProvider from "ra-data-simple-rest";
import { saveAs } from "file-saver";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import  ConfirmContent  from '../bookings/confirmContent'
import PrintIcon from '@mui/icons-material/Print';
import { apiUrl } from "../ApiUrl";
import { Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const BookingList = props =>
{

 const [pdfValue, setPdfValue] = useState('');
 const [pageNumber, setPageNumber] = useState(1);
 const [dialogIsOpen, setDialogIsOpen] = React.useState(false)

  const openDialog = () => setDialogIsOpen(true)
  const closeDialog = () => setDialogIsOpen(false)
  const [openConfirm, setConfirmOpen] = useState(false);
  const refresh = useRefresh();

  const handleCancelClick = () => setConfirmOpen(true);
  const handleDialogClose = () => setConfirmOpen(false);
 
  const handleConfirm = () => {
    
    if(reasonValue ===""){
        notify('Reason is required.');
    }
    else{
        let promises = [];
        for (var i = 0; i < Ids.length; i++) {
            console.log(apiUrl + "/bookings/" + Ids[i]);
            console.log(reasonValue);
           const response =   axios.put(apiUrl + "/bookings/" + Ids[i] ,{
                                    id: Ids[i],
                                    cancelledReason:reasonValue,
                                    clientId:userInfo.clientId
                                    })
                                   .then((res) => {
                                     refresh();    
                                   });
            console.log(response);
            promises.push(response);
         }
         
         setConfirmOpen(false);
    }
  };
  const redirect = useRedirect();
  
useEffect(() => { 
     if(userInfo.roleId === 0)
     {
        redirect('/');
     }
      if(openConfirm)
      {
        setReasonValue('');
      }
      
},[openConfirm]);





  const [Ids, setIds] = React.useState('')
    function uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
      }
   

    const downloadButton = () =>{
        const iids =[];
        setIsDownloading(true);
        for (var i = 0; i < Ids.length; i++) {
            const id = {
                Id: Ids[i].toString() 
            }
            iids.push(id);
        }
        console.log(JSON.stringify(iids));
        const customConfig = {
            headers: {
            'Content-Type': 'application/json'
            },
            responseType:'blob'
        };
         const result =  axios.post(apiUrl + "/shipmentlabel", 
                                            JSON.stringify(iids), 
                                            customConfig)
                                    .then((res) => {
                                            var filename =  uuidv4() + ".pdf";
                                            saveAs(res.data,filename);
                                            setIsDownloading(false);
                                     })
                                     .catch(function (response) {
                                        setIsDownloading(false);
                                        console.log(response);
                                    });
    }
      
    const handleDownloadTemplate =() =>{
        axios({
            method: 'get',
            url:  apiUrl + "/DownloadTemplate",
             responseType:"arraybuffer",                         
            
            })
            .then(function (response) {
                const blob = new Blob([response.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, uuidv4() + ".xlsx");
            })
            .catch(function (response) {
                //handle error
               //np alert(response);
               
                console.log(response);
            });
    }

     
    
    const [userInfo, setUserInfo] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("auth");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

    //   var iframe =null;
      const HandelPrintButton = () =>{
        const iids =[];
        setIsPrinting(true);
        for (var i = 0; i < Ids.length; i++) {
            const id = {
                Id: Ids[i].toString() 
            }
            iids.push(id);
        }
        console.log(JSON.stringify(iids));
        const customConfig = {
            headers: {
            'Content-Type': 'application/json'
            },
            responseType:'blob'
        };
         const result =  axios.post(apiUrl + "/shipmentlabel", 
                                            JSON.stringify(iids), 
                                            customConfig)
                                    .then((res) => {
                                      var blob = new Blob([res.data], {type: 'application/pdf'}); //this make the magic
                                    var blobURL = URL.createObjectURL(blob);

                                    var iframe =  document.createElement('iframe'); //load content in an iframe to print later
                                    iframe =  document.createElement('iframe'); 
                                    iframe.style.display = 'none';
                                    iframe.src = blobURL;
                                    document.body.appendChild(iframe);
                                   
                                    iframe.onload = function() {
                                      setTimeout(function() {
                                        iframe.focus();
                                        iframe.contentWindow.print();
                                      
                                      }, 1);
                                    };
                                    setIsPrinting(false);
                            })
                            .catch(function (response) {
                                setIsPrinting(false);
                                console.log(response);
                            });
       
     }
      
    const { permissions } = usePermissions();
    const { identity, isLoading: identityLoading } = useGetIdentity();
    
    const ListPanel = ({ id, record, resource }) => (
        <div dangerouslySetInnerHTML={{ __html: record.id }} />
    );

    const ListActions = () => (
        
        <TopToolbar>
           
            <CreateButton />
                    <Button
                        label="Upload"
                        onClick={() => {  openDialog(true); }}
                        disabled={userInfo.roleId ===30}
                    >
                        <UploadFileIcon/>
                    </Button>
                    <Button 
                    onClick={() => {  handleDownloadTemplate(); }}
                        label="Download Template"
                        disabled={userInfo.roleId ===30}
                    >
                        <DownloadIcon/>
                    </Button>
                </TopToolbar>
    
    );
    const [isPrinting, setIsPrinting] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const BookingBulkActionButtons = props => (
        <Fragment>
             <UnselectButton  />  
             <Button
                onClick={handleCancelClick} 
                label="Cancel"
            >
                <CancelRoundedIcon/>
            </Button>
             <Button  
                onClick={() => {  downloadButton(); }}
                disabled={isDownloading}>
                {isDownloading && <CircularProgress   color="secondary" />}
                {!isDownloading && <DownloadIcon/>}
                {!isDownloading && 'Download'}
                
            </Button>
            <Button  onClick={() => {  HandelPrintButton(); }}
                 disabled={isPrinting}>
                 {isPrinting && <CircularProgress   color="secondary" />}
                 {!isPrinting && <PrintIcon/>}
                 {!isPrinting && 'Print'}
            </Button>
        </Fragment>
    );

    const UnselectButton = (props) => {
        const { resource, selectedIds } = useListContext();
        const unselect = useUnselect(resource);
        useEffect(() => { 
            setIds(selectedIds);
        },[selectedIds]);
      
        
        return (
            <button hidden={true} >
                 {`${selectedIds}`}
            </button>
        );
    };
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;
    const convertDateToString = value => {
        // value is a `Date` object
        if (!(value instanceof Date) || isNaN(value.getDate())) return '';
        const pad = '00';
        const yyyy = value.getFullYear().toString();
        const MM = (value.getMonth() + 1).toString();
        const dd = value.getDate().toString();
        return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
    };
    const dateFormatter = value => {
        // null, undefined and empty string values should not go through dateFormatter
        // otherwise, it returns undefined and will make the input an uncontrolled one.
        if (value == null || value === '') return '';
        if (value instanceof Date) return convertDateToString(value);
        // Valid dates should not be converted
        if (dateFormatRegex.test(value)) return value;
    
        return convertDateToString(new Date(value));
    };
    
    const dateParser = value => {
        //value is a string of "YYYY-MM-DD" format
        const match = dateParseRegex.exec(value);
        if (match === null) return;
        const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
        if (isNaN(d.getDate())) return;
        return d;
    };
   const bookingsFilters = [
    <TextInput label="Search" source="q" 
        alwaysOn placeholder="Enter 2GO Tracking  or Consignee Name" style={{ width: 400  }}    />,
        <DateInput source="dateFrom" label="Created From" alwaysOn   />,
        <DateInput source="dateTo" label="To" alwaysOn    />,
    ];

    const notify = useNotify();
    const [reasonValue, setReasonValue] = React.useState('')
    const passData = (data) => {
       setReasonValue(data);
     
   };
   const BookingPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;
   const RecordPanel = ( ) => {
    const record = useRecordContext();
     
    return (
        <div>
        <Grid  container columnSpacing={1} >
            <Grid item xs={12} sm={2}>
                <p fullWidth>Account No: <strong> {record.shipperAccountNo}  </strong></p>
            </Grid>
            <Grid item xs={12} sm={2}>
                <p fullWidth>Product Type: <strong> {record.productCode}  </strong></p>
            </Grid>
            <Grid item xs={12} sm={3} >
                <p fullWidth >Package Type:  <strong> {record.packingType}  </strong></p>
            </Grid>
            
            <Grid item xs={12} sm={4}>
                <p>Description: <strong> {record.packingDescription}  </strong></p>
            </Grid>
            <Grid item xs={12} sm={1} >
                <p fullWidth >Quantity:  <strong> {record.quantity}  </strong></p>
            </Grid>
            
            <Grid item xs={12} sm={2}>
                <p fullWidth>Declared value: <strong> {record.declaredValue}  </strong></p>
            </Grid>
            <Grid item xs={12} sm={2} >
                <p fullWidth >Weight:  <strong> {record.weight}  </strong></p>
            </Grid>
            <Grid item xs={12} sm={2} >
                <p fullWidth >Length:  <strong> {record.length}  </strong></p>
            </Grid>
            <Grid item xs={12} sm={2} >
                <p fullWidth >Width:  <strong> {record.width}  </strong></p>
            </Grid>
            <Grid item xs={12} sm={3} >
                <p fullWidth >Height:  <strong> {record.height}  </strong></p>
            </Grid>
            <Grid item xs={12} sm={5} >
                <p fullWidth >Pickup Date:  <strong> {record.pickupDate}  </strong></p>
            </Grid>
            
        </Grid>
        </div>
    );
};

   return(
       <div>
            <Confirm
                isOpen={openConfirm}
                title="Cancel booking"
                content={<ConfirmContent passData={passData}/>}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
         <List {...props}   
                actions={<ListActions/>}
                bulkActionButtons={<BookingBulkActionButtons/>}
                filters={bookingsFilters} 
                filter={{ clientId: userInfo.clientId, userName:userInfo.userName}}
                pagination={<BookingPagination />}
                sort={{ field: 'id', order: 'DESC' }}
                perPage={10}
               
                >
                <Datagrid
              size="small"
              expand={<RecordPanel/>}
             
                >
                    <TextField source="code" label="2GO Tracking"/> 
                    <TextField source="consigneeName" label="Consignee Name"  />
                    
                   
                    <TextField source="attachment" label="Attachment" />
                    <TextField source="referenceNo" label="Reference No" />
                    <TextField source="status" />
                    <TextField source="cancelledReason" label="Remarks" /> 
                    <TextField source="dateCreated" label="Date Created" /> 
                  
                   
                    
                </Datagrid>
           </List>
           <BulkUploadingModal open={dialogIsOpen} onClose={closeDialog} />
            
           </div>  
         );

};
export default BookingList;