import React, { useEffect, useState,Fragment } from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Pagination,
    useRedirect
} from "react-admin";

const RoleList = props =>
{
    const redirect = useRedirect();
    useEffect(() => { 
        if(userInfo.roleId === 0)
        {
           redirect('/');
        }
        if(userInfo.roleId === 1)
        {
           redirect('/');
        }
        if(userInfo.roleId === 5 || userInfo.roleId === 6 )
        {
           redirect('/');
        }
   },[]);

    const RolePagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;
    const [userInfo, setUserInfo] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("auth");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
      
      if(userInfo.roleId === 0)
      {
         redirect('/');
      }
    return(
        <div>
            <List {...props} 
                filter={{ clientId: userInfo.clientId, 
                    userName:userInfo.userName,
                    accountNo:userInfo.accountNo}}
                pagination={<RolePagination />}
               sort={{ field: 'id', order: 'DESC' }}
               perPage={10}
            >
            <Datagrid
              size="small"
              container spacing={1}
                >
                    <TextField source="accountNo" label="Account No" style={{ width:"2%"}}   /> 
                    <TextField source="accountName" label="Account Name" style={{ width:"2%"}}   /> 
                    <TextField source="code" label="Code"/> 
                    <TextField source="description" label="Description"  />
                    <EditButton />
                </Datagrid>
             </List>
      </div>
    )
};

export default RoleList;